import * as Style from './style';
import flagPoland from '../../assets/images/navigation/flag-poland.svg';
import flagUkraine from '../../assets/images/navigation/flag-ukraine.svg';
import chevronDownIcon from '../../assets/images/icons/chevron-down.svg';
import {useRef, useState} from 'react';
import i18next from 'i18next';
import {withTranslation} from "react-i18next";
import useOnClickOutside from "../../library/hooks/useOnClickOutside";

const LanguageSwitcher = ({i18n}) => {
  const [showOptions, setShowOptions]= useState(false);
  const lang = i18n.language;

  const languageSwitcherRef = useRef();

  useOnClickOutside(languageSwitcherRef, () => setShowOptions(false));

  const onOptionItemClick = (lang) => {
    i18next.changeLanguage(lang);
    setShowOptions(false);
  }

  const LanguageOptionItem = ({lang, icon}) => {
    return (
      <Style.LanguageSwitcherOptionItem onClick={() => onOptionItemClick(lang)}>
        <Style.LanguageSwitcherFlag imgSrc={lang === 'ua' ? flagUkraine : flagPoland} alt="Zmień język: Polski" />
        <span>{lang === 'ua' ? 'UA' : 'PL'}</span>
        {icon && <Style.LanguageSwitcherSelectArrow src={chevronDownIcon} alt="Zmień język: Ikona" reverted={showOptions} />}
      </Style.LanguageSwitcherOptionItem>
    )
  }

  return (
    <Style.LanguageSwitcher ref={languageSwitcherRef}>
      <Style.LanguageSwitcherTrigger onClick={() => setShowOptions(!showOptions)}>
        <LanguageOptionItem lang={lang} icon={true} />
      </Style.LanguageSwitcherTrigger>
      { showOptions &&
        <Style.LanguageSwitcherOptions>
          <LanguageOptionItem lang={lang === 'ua' ? 'pl' : 'ua'} />
        </Style.LanguageSwitcherOptions>
      }
    </Style.LanguageSwitcher>
  )
}

export default withTranslation()(LanguageSwitcher);