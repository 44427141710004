import * as Style from './style';
import GoBackButton from "../GoBackButton";

const PageHeader = ({text, onBack, subtitle}) => {
  return (
    <Style.PageHeader>
      <GoBackButton onBack={onBack} />
      <div>
        <Style.PageHeaderHeadlineTitle>{text}</Style.PageHeaderHeadlineTitle>
        <Style.PageHeaderHeadlineSubtitle>{subtitle}</Style.PageHeaderHeadlineSubtitle>
      </div>
    </Style.PageHeader>
  )
}

export default PageHeader;