const institution = {
  specializations: 'Specjalizacje',
  entry: 'Wejście/izba przyjęć',
  registration: 'Zapisy',
  parking: 'Parking',
  atLocation: 'Na miejscu',
  rules: 'Zasady',
  information: 'Informacje',
  whatToTake: 'Co zabrać',
  infrastructure: 'infrastruktura',
  patientsRoom: 'pokój pacjenta',
  feeding: 'wyżywienie',
  child: 'dziecko',
  parent: 'rodzic',
  documents: 'dokumenty',
  worthToTake: 'warto zabrać',
  seeMore: 'Zobacz więcej',
  paid: 'płatny',
  free: 'bezpłatny',
  address: 'adres',
  city: 'miasto',
  parentUnit: 'jednostka nadrzędna',
  open: 'Godziny otwarcia',
  phone: 'telefon',
  showAllPhotos: 'Pokaż wszystkie zdjęcia',
  accomodation: 'Zakwaterowanie',
  voivodeship: 'województwo',
  showMore: 'Pokaż więcej',
  showLess: 'Pokaż mniej'
}

export default institution;