import styled from 'styled-components';
import {themeGet} from '@styled-system/theme-get';

export const GoBackButton = styled.div`
  width: 48px;
  height: 72px;
  min-width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${themeGet('color.primary')};
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  cursor: pointer;
  user-select: none;
  
  &:hover {
    background-color: ${themeGet('color.secondary')};
  }
  
  &:active {
    background-color: ${themeGet('color.tertiary')};
  }
  
  @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
    display: none;
  }
  
`;