import { themeGet } from '@styled-system/theme-get';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  //body {
  //  height: 100%;  
  //}
  
  body, body * {
    color: ${themeGet('textColor.primary')};
  }
  
  body * {
    transition-duration: .15s;
  }
`;

export default GlobalStyle;