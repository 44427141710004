import styled from 'styled-components';
import {themeGet} from '@styled-system/theme-get';

export const Button = styled.div`
  color: ${themeGet('color.white')};
  background-color: ${props => props.type === 'light' ? themeGet('color.white') : themeGet('color.primary')}; 
  color: ${props => props.type === 'light' ? themeGet('textColor.primary') : themeGet('color.white')}; 
  font-weight: 500;
  white-space: nowrap;
  padding: ${props => props.size === 'small' ? '10px 16px' : '16px'}; 
  width: ${props => props.fullWidth ? '100%' : 'max-content'};
  text-align: center;
  border-radius: ${themeGet('borderRadius.medium')};
  font-size: ${props => props.size === 'small' ? '14px' : '16px'};
  line-height: ${props => props.size === 'small' ? '21px' : '24px'};
  letter-spacing: -.3px;
  cursor: pointer;
  display: flex;
  gap: 8px;
  align-items: center;

  img {
    width: 16px;
  }
  
  img.Button__arrow-light, img.Button__link-light {
    display: none;
  }

  &:hover {
    background-color: ${props => props.type === 'light' ? themeGet('textColor.primary') : themeGet('color.secondary')};
    color: ${themeGet('color.white')};
    
    img.Button__arrow {
      display: none;
    }

    img.Button__arrow-light {
      display: block;
    }

    img.Button__link {
      display: none;
    }

    img.Button__link-light {
      display: block;
    }
  }
  
  &:active {
    background-color: ${props => props.type === 'light' ? themeGet('color.greyDark') : themeGet('color.tertiary')};
    color: ${themeGet('color.white')};
  }
`;