import * as Style from './style';
import arrowLeftIcon from '../../../src/assets/images/icons/arrow-left.svg';
import {useNavigate} from 'react-router-dom';

const GoBackButton = ({onBack}) => {
  const navigate = useNavigate();

  return (
    <Style.GoBackButton onClick={onBack ? onBack : () => navigate(-1)}>
      <img src={arrowLeftIcon} alt="Ikona: Cofnij" />
    </Style.GoBackButton>
  )
}

export default GoBackButton;