import {withErrorBoundary} from 'react-error-boundary'
import StaticPageError from '../../container/StaticPage/StaticPageError';

export const withErrorBoundaries = (component) => {
  return withErrorBoundary(component, {
    FallbackComponent: () => {
      return (
        <StaticPageError
          title="Wystąpił błąd"
        />
      );
    }
  }) 
}

const ErrorBoundaries = ({children}) => (<>{children}</>);

export default withErrorBoundaries(ErrorBoundaries);
