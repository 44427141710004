import styled from 'styled-components';
import {themeGet} from '@styled-system/theme-get';

export const StaticPageHeader = styled.div`
  background-color: ${themeGet('color.greyLight')};
  padding: 24px ${themeGet('contentPadding.horizontal.mobile')};

  @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
    padding: 24px ${themeGet('contentPadding.horizontal.desktop')};
  }
`;

export const StaticPageHeaderImage = styled.div`
  background-image: url("${props => props.imgSrc}");
  background-position: center;
  background-size: cover;
  margin-top: 32px;
  width: 100%;
  height: 175px;
  border-radius: ${themeGet('borderRadius.medium')};

  @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
    height: 240px;
  }
`;

export const StaticPageContent = styled.div`
  padding: 32px ${themeGet('contentPadding.horizontal.mobile')};
  
  @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
    padding: 32px ${themeGet('contentPadding.horizontal.desktop')};
    width: ${themeGet('maxContentWidth')};
  }
`;