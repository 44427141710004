import StaticPageError from '../StaticPageError';
import {withTranslation} from 'react-i18next';

const StaticPage404 = ({t}) => (
  <StaticPageError
    title="Nie znaleziono strony"
    desc="Podana strona nie istnieje"
  />
);

export const StaticPage404Wrapper = ({children, t, isNotFoundError = false}) => (
  <>{isNotFoundError ? <StaticPage404 t={t} /> : children}</>
);

export default withTranslation()(StaticPage404);