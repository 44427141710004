const common = {
  more: 'Więcej',
  find: 'Wyszukaj',
  'hospital-ward': 'oddział',
  clinic: 'poradnia',
  findObjects: 'Wyszukaj obiekty',
  enterPhrase: 'Wpisz wyszukiwaną frazę',
  objects: 'obiekty',
  specializationList: 'Lista specjalizacji',
  voivodeship: 'województwo',
  voivodeshipCapital: 'Województwo',
  searchResultsFor: 'Wyniki wyszukiwania dla: ',
  infoMessageLanguageTitle: 'Не всі елементи сторінки були перекладені',
  infoMessageLanguageContent:
    `На цій сторінці є елементи без перекладу українською мовою. У таких місцях відображається польська версія.`,
  list: 'lista',
  homePage: 'Strona główna',
  allCapitalized: 'Wszystkie',
  chooseCity: 'Wybierz miasto',
  listIsEmpty: 'Brak placówek.',
  searchTooltipIfEmpty: 'Wpisz czego szukasz'
}

export default common;