import styled from 'styled-components';
import {themeGet} from '@styled-system/theme-get';

export const NavigationBar = styled.div`
  padding: 0 ${themeGet('contentPadding.horizontal.mobile')};
  background: ${themeGet('color.white')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 72px;
  z-index: 10;
  
  @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
    padding: 48px calc(48px + ${themeGet('contentPadding.horizontal.desktop')});
  }
`;

export const Logo = styled.img`
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  width: 96px;
  
  @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
    font-size: 32px;
  }
`;