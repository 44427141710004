import homePage from './ua/home-page';
import common from './ua/common';
import institution from "./ua/institution";

const ua = {
  translations: {
    common,
    homePage,
    institution
  }
}

export default ua;