import styled from 'styled-components';
import {themeGet} from '@styled-system/theme-get';

export const LanguageSwitcher = styled.div`
  font-weight: 600;
  letter-spacing: -.3px;
  cursor: pointer;
  position: relative;
`;

export const LanguageSwitcherTrigger = styled.div`
  background: ${themeGet('color.white')};
`;

export const LanguageSwitcherOptions = styled.div`
  background: ${themeGet('color.white')};
  position: absolute;
  width: 100%;
  z-index: 10;
  top: 100%;
  border-bottom-left-radius: ${themeGet('borderRadius.default')};
  border-bottom-right-radius: ${themeGet('borderRadius.default')};
`;

export const LanguageSwitcherOptionItem = styled.div`
  display: flex;
  gap: 8px;
  padding: 8px;
  align-items: center;
  width: 100%;
  background: ${themeGet('color.white')};
  
  &:hover {
    background: ${themeGet('color.greyLight')};
  }
`;

export const LanguageSwitcherFlag = styled.div`
  width: 24px;
  height: 24px;
  background-image: url("${props => props.imgSrc}");
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  background-repeat: no-repeat;
`;

export const LanguageSwitcherSelectArrow = styled.img`
  height: 8px;
  
  ${props => {
    if (props.reverted) return 'transform: rotate(180deg)';
  }};
`;