import StaticPage from '../index';
import Button from '../../../components/Button';
import * as Style from './style';

const StaticPageError = ({title, desc}) => {
  return (
    <StaticPage
      title={title}
    >
      <Style.StaticPageErrorContent>
        { desc }
        <a href="/">
          <Button text="Wróć do strony głównej" />
        </a>
      </Style.StaticPageErrorContent>
    </StaticPage>
  )
}

export default StaticPageError;