import * as Style from './style';
import linkIcon from "../../assets/images/icons/link.svg";
import linkLightIcon from "../../assets/images/icons/link-light.svg";
import arrowRightIcon from "../../assets/images/icons/arrow-right.svg";
import arrowRightLight from "../../assets/images/icons/arrow-right-light.svg";

const Button = ({text, onClick, fullWidth, type, size, outsideAction}) => {
  return (
    <Style.Button onClick={onClick} fullWidth={fullWidth} type={type} size={size}>
      {
        outsideAction && (
          <>
            <img className="Button__link" src={linkIcon} alt="Ikona: Link" />
            <img className="Button__link-light" src={linkLightIcon} alt="Ikona: Link" />
          </>
        )
      }
      {text}
      {
        outsideAction && (
          <>
            <img className="Button__arrow" src={arrowRightIcon} alt="Ikona: Przejdź" />
            <img className="Button__arrow-light" src={arrowRightLight} alt="Ikona: Przejdź" />
          </>
        )
      }
    </Style.Button>
  )
}

export default Button;