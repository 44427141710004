const defaultTheme = {
  color: {
    white: '#FFFFFF',
    primary: '#94268F',
    secondary: '#ED217D',
    secondaryLight: '#FCDAE9',
    tertiary: '#F471AC',
    greyLight: '#F2F5F7',
    greyDark: '#6D7685',
    blue: '#468DC7',
    lightBlue: '#E7F0F8',
    green: '#519E8A',
    lightGreen: '#E7F1F0',
    orange: '#F7941D',
    lightOrange: '#FDF1E1',
    primaryDark: '#212529',
    infoMessage: '#E88D67'
  },
  textColor: {
    primary: '#212529',
    secondary: '#828B99',
  },
  breakpoint: {
    medium: '768px',
    large: '991px',
    xLarge: '1441px'
  },
  borderRadius: {
    small: '4px',
    medium: '6px',
    large: '12px'
  },
  opacity: {
    default: '.85',
    small: '.4',
  },
  contentPadding: {
    horizontal: {
      mobile: '16px',
      desktop: '8%'
    }
  },
  maxContentWidth: '80%'
}

export default defaultTheme;