export const HOME_PAGE = '/';

export const INSTITUTION_PAGE = '/placowka';

export const RESULTS_VOIVODESHIP_PAGE = '/wojewodztwa';

export const RESULTS_SPECIALIZATIONS_PAGE = '/specjalizacje';

export const RESULTS_SPECIALIZATION_PAGE = '/specjalizacje';

export const RESULTS_CITY_PAGE = '/miasta';

export const RESULTS_LIST_PAGE = '/lista';
