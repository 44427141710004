import styled from 'styled-components';
import {themeGet} from '@styled-system/theme-get';

export const Footer = styled.div`
  font-size: 14px !important;
  position: relative;
  left: 0;
  right: 0;
  bottom: 0 !important;
  height: 96px;
  color: ${themeGet('color.greyDark')};
  background-color: #F9FAFB;
  border-top: 1px solid ${themeGet('color.greyLight')};
  display: block;
  padding: 16px ${themeGet('contentPadding.horizontal.mobile')};
  text-align: left;
  
  a {
    color: ${themeGet('textColor.secondary')};
    text-decoration: none;
    
    img {
      height: 42px;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
      
      &:hover {
        opacity: ${themeGet('opacity.default')};
      }
    }
    
    &:hover {
      color: ${themeGet('color.secondary')};
    }
  }
  
  a.FooterLogo__polcode {
    background: ${themeGet('color.white')};
  }
  
  @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
    padding: 16px calc(48px + ${themeGet('contentPadding.horizontal.desktop')});
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    gap: 16px;
    align-items: center;
    text-align: center;
    position: absolute;
    bottom: 0 !important;
    height: unset;
  }
`;

export const FooterLogos = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  
  @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
    margin-bottom: 0;
  }
`;
