import * as Style from './style';
import tttrBadge from '../../assets/images/tttr-badge.svg';
import polcodeLogo from '../../assets/images/polcode-logo.svg';
import {withTranslation} from "react-i18next";

const Footer = () => {
  return (
    <Style.Footer>
      <Style.FooterLogos>
        <a
          href="https://www.techtotherescue.org/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={tttrBadge} alt="Logo: Tech to the rescue" />
        </a>
        <a
          href="https://polcode.com/"
          target="_blank"
          rel="noreferrer"
          className="FooterLogo__polcode"
        >
          <img src={polcodeLogo} alt="Logo: Polcode" />
        </a>
      </Style.FooterLogos>
      <div>
        <a href="https://kids.org.pl/" target="_blank">Klub Innowatorów Dziecięcych Szpitali</a> &copy; 2022
      </div>
    </Style.Footer>
  )
}

export default withTranslation()(Footer);