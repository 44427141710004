import * as Style from './style';
import LanguageSwitcher from '../../components/LanguageSwitcher';
import {useNavigate} from 'react-router-dom';
import logoSrc from '../../assets/images/logo.png';

const NavigationBar = () => {
  const navigate = useNavigate();

  return (
    <Style.NavigationBar>
      <Style.Logo onClick={() => navigate('/')} src={logoSrc} />
      <LanguageSwitcher />
    </Style.NavigationBar>
  )
}

export default NavigationBar;