import styled from 'styled-components';
import {themeGet} from '@styled-system/theme-get';

export const PageHeader = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  
  @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
    gap: 24px;
  }
`;

export const PageHeaderHeadlineTitle = styled.h1`
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: -1px;
  
  @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -.3px;
  }
`;

export const PageHeaderHeadlineSubtitle = styled.div`
  font-size: 16px;
  color: ${themeGet('color.greyDark')};
  cursor: pointer;
  
  @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
    letter-spacing: -.3px;
  }
`;