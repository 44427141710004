import * as Style from './style';
import PageHeader from '../../components/PageHeader';

const StaticPage = ({title, imgSrc, onBack, children}) => {
  return (
    <>
      <Style.StaticPageHeader>
        <PageHeader text={title} onBack={onBack} />
        { imgSrc && <Style.StaticPageHeaderImage imgSrc={imgSrc} />}
      </Style.StaticPageHeader>
      <Style.StaticPageContent>
        {children}
      </Style.StaticPageContent>
    </>
  )
}

export default StaticPage;