const homePage = {
  nationwide: 'Ogólnopolska',
  branchDatabase: 'baza oddziałów',
  andChildrenClinics: 'i poradni dziecięcych',
  searchObjectInVoivodeship: 'Szukaj obiektu w województwie',
  objects: 'obiekty',
  findSpecialization: 'Wyszukaj specjalizację',
  specializations: 'specjalizacji',
  patientsLaw: 'Prawa pacjentów',
  patientsLawDesc: 'Dowiedz się jakie prawa przysługują pacjentowi w szpitalu',
}

export default homePage;