import homePage from './pl/home-page';
import common from './pl/common';
import institution from "./pl/institution";

const pl = {
  translations: {
    common,
    homePage,
    institution
  }
}

export default pl;