const homePage = {
  nationwide: 'По всій країні',
  branchDatabase: 'база даних філій',
  andChildrenClinics: 'та дитячі поліклініки',
  searchObjectInVoivodeship: 'Пошук об\'єкта у воєводстві',
  objects: 'об\'єкти',
  findSpecialization: 'Пошук спеціалізації',
  specializations: 'спеціалізацій',
  patientsLaw: 'Права українських пацієнтів',
  patientsLawDesc: 'Дізнайтеся, які права українські пацієнти мають у польській лікарні'
}

export default homePage;