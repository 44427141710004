import styled from 'styled-components';
import {themeGet} from '@styled-system/theme-get';

export const LayoutContent = styled.div`
  margin-top: 72px;
  min-height: calc(100vh - 72px - 96px);

  @media only screen and (min-width: ${themeGet('breakpoint.large')}) {
    min-height: unset;
  }
`;