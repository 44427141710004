const institution = {
  specializations: 'спеціалізації',
  entry: 'Приймальна кімната',
  registration: 'Прийоми',
  parking: 'Паркінг',
  atLocation: 'На місці',
  rules: 'Правила',
  whatToTake: 'Що взяти',
  infrastructure: 'Інфраструктура',
  patientsRoom: 'Кімната пацієнта',
  feeding: 'Дошка',
  child: 'дитина',
  parent: 'батькові',
  documents: 'документів',
  worthToTake: 'варто взяти',
  seeMore: 'Побачити більше',
  paid: 'Платний',
  free: 'безкоштовно',
  address: 'Адреса',
  city: 'Місто',
  parentUnit: 'Батьківський блок',
  open: 'Активний',
  phone: 'Телефон',
  showAllPhotos: 'Показати всі фото',
  accomodation: 'Проживання',
  voivodeship: 'воєводство',
  showMore: 'Показати більше',
  showLess: 'Показувати менше'
}

export default institution;