import pl from './pl';
import ua from './ua';

const config = {
  resources: {
    pl: pl,
    ua: ua,
  },
  fallbackLng: {
    'default': ['pl']
  },
  lng: 'pl',
  debug: false,
  defaultNS: 'translations',
  keySeparator: '.',
  interpolation: {
    formatSeparator: false
  },
  react: {
    wait: true
  }
}

export default config;
