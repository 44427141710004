import styled from 'styled-components';
import {themeGet} from '@styled-system/theme-get';

export const StaticPageErrorContent = styled.div`
  width: 100%;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px;
  border-radius: ${themeGet('borderRadius.medium')};
  gap: 32px;
  background-color: ${themeGet('color.greyLight')};
  
  a {
    text-decoration: none;
    color: unset;
  }
`;