const common = {
  more: 'Більше',
  find: 'пошук',
  'hospital-ward': 'Відділення',
  clinic: 'Поліклініка',
  findObjects: 'Пошук об\'єкту',
  enterPhrase: 'введіть пошуковий термін',
  objects: 'об’єкти',
  specializationList: 'Перелік спеціалізацій',
  voivodeship: 'воєводство',
  voivodeshipCapital: 'воєводство',
  searchResultsFor: 'Результати пошуку для: ',
  list: 'список',
  homePage: 'домашня сторінка',
  allCapitalized: 'все',
  chooseCity: 'Виберіть місто',
  listIsEmpty: 'Розеток немає.'
}

export default common;