import './App.css';
import {ThemeProvider} from 'styled-components';
import theme from './themes/default.theme';
import NavigationBar from './container/NavigationBar';
import LayoutContent from './container/Layout';
import RouterProvider from './context/RouterProvider';
import RoutesWrapper from './router';
import {BrowserRouter} from 'react-router-dom';
import Footer from './container/Footer';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <div className="App">
          <NavigationBar />
          <LayoutContent>
            <RouterProvider>
              <RoutesWrapper />
            </RouterProvider>
          </LayoutContent>
          <Footer />
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
