import * as Style from './style';
import Footer from "../Footer";
import ErrorBoundaries from '../../library/helpers/withErrorBoundary';
import {useState} from "react";
import {Fade} from 'react-reveal';

const Layout = ({children, location}) => {
  const [mountedLocation, setMountedLocation] = useState(null);

  return (
    <Style.LayoutContent>
      <>
        <Fade
          spy={location}
          wait={1000}
          onReveal={() => (mountedLocation === location || setMountedLocation(location))}
        >
          <>
            <ErrorBoundaries>
              {children}
            </ErrorBoundaries>
          </>
        </Fade>
      </>
    </Style.LayoutContent>
  )
}

export default Layout;