import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {
  RESULTS_VOIVODESHIP_PAGE,
  INSTITUTION_PAGE,
  RESULTS_CITY_PAGE,
  HOME_PAGE,
  RESULTS_SPECIALIZATIONS_PAGE,
  RESULTS_SPECIALIZATION_PAGE,
  RESULTS_LIST_PAGE
} from './settings/consts';
import Loadable from 'react-loadable';
import StaticPage404 from "./container/StaticPage/StaticPage404";

const Loading = () => null;

const routes = [
  {
    path: HOME_PAGE,
    Component: Loadable({
      loader: () =>
        import(/* webpackChunkName: 'Home' */ './container/Home'),
      loading: Loading,
      modules: ['Home']
    }),
    exact: true
  },
  {
    path: `${INSTITUTION_PAGE}/:slug`,
    Component: Loadable({
      loader: () =>
        import(/* webpackChunkName: 'Home' */ './container/Institution'),
      loading: Loading,
      modules: ['Institution']
    }),
    exact: true
  },
  {
    path: `${RESULTS_VOIVODESHIP_PAGE}/:slug`,
    Component: Loadable({
      loader: () =>
        import(/* webpackChunkName: 'Home' */ './container/SearchResults/SearchResultsVoivodeship'),
      loading: Loading,
      modules: ['SearchResultsVoivodeship']
    }),
    exact: true
  },
  {
    path: `${RESULTS_CITY_PAGE}/:slug`,
    Component: Loadable({
      loader: () =>
        import(/* webpackChunkName: 'Home' */ './container/SearchResults/SearchResultsCity'),
      loading: Loading,
      modules: ['SearchResultsCity']
    }),
    exact: true
  },
  {
    path: RESULTS_SPECIALIZATIONS_PAGE,
    Component: Loadable({
      loader: () =>
        import(/* webpackChunkName: 'Home' */ './container/SearchResults/SearchResultsSpecializations'),
      loading: Loading,
      modules: ['SearchResultsSpecializations']
    }),
    exact: true
  },
  {
    path: `${RESULTS_SPECIALIZATION_PAGE}/:slug`,
    Component: Loadable({
      loader: () =>
        import(/* webpackChunkName: 'Home' */ './container/SearchResults/SearchResultsSpecialization'),
      loading: Loading,
      modules: ['SearchResultsSpecialization']
    }),
    exact: true
  },
  {
    path: `${RESULTS_LIST_PAGE}/:slug`,
    Component: Loadable({
      loader: () =>
        import(/* webpackChunkName: 'Home' */ './container/SearchResults/SearchResultsList'),
      loading: Loading,
      modules: ['SearchResultsList']
    }),
    exact: true
  }
];



const RoutesWrapper = () => {
  return (
    <Routes>
      {routes.map(({path, Component, exact = false}) => (
        <Route key={path} path={path} exact={exact} element={<Component />} />
      ))}
      <Route path="*" element={<StaticPage404 />} />
    </Routes>
  );
}

export default RoutesWrapper;